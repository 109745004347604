import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const VideoConference = ({data}) => {
return <Layout>
    <Helmet>
        <title>NamasteGod - Perform Puja Via Video Conferencing</title>
        <meta name="description" content="NamasteGod - Perform Puja Via Video Conferencing. During these days of COVID-19(Corona Virus), Video Conferencing is a safe option. You can maintain social distancing and perform puja from the comfort of your own home."/>
    </Helmet>
    <div id="main" className="notfound">
        <section id="one">
            <div className="inner">
                <header className="major">
                    <h1>Perform Puja via Video Conferencing</h1>
                </header>
                <center><img src={data.allFile.nodes[0].childImageSharp.fluid.src} alt="NamasteGod Puja Online" title="NamasteGod Puja Online"/></center>
                <ol>
                    <li className='bold'>Why should I do Puja through Video Conferencing?
                    <span>During these days of COVID-19(Corona Virus), Video Conferencing is a safe option. You can maintain social distancing and perform puja from the comfort of your own home.</span></li>
                    <li className='bold'>How will Pandit interact with us? Isn't it difficult to perform puja online?
                    <span>Our pandits will guide you on arranging the Puja Samagri, chanting of mantras and explaining all the rituals involved in the puja as a part of the vidi, vidhan. All of our pandits are well equiped to perform the puja through video conferencing. The Puja would be performed through video conferencing apps like WhatsApp and Skype.</span></li>
                    <li className='bold'>Should I arrange Puja Samagri?
                    <span>You can choose between two options. <br/>
                    1. NamasteGod team would deliver the Samagri to your residence 1 or 2 days before the puja<br/>
                    2. You can arrange the Samagri by yourself</span></li>
                    <li className='bold'>When should I do the payment?
                    <span>If you opt for the NamasteGod team to deliver the Puja Samagri at your residence, then you would need to pay us a token amount as advance and the remaining amount after the delivery of Samagri. Incase you are arranging Puja Samagri by yourself, then you would need to pay the full amount upfront at once.</span></li>
                    <li className='bold'>What are the payment options available? Cash or Wallet or Netbanking?
                    <span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay, PhonePe) or e-Wallet (PayTM).</span></li>
                </ol>                
            </div>
        </section>
    </div>
  </Layout>
}

export const query = graphql`
{
  allFile(filter: {name: {in: ["Namastegod Puja Online"]}} , sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
}`

export default VideoConference
